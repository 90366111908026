import Term from './components/Term.js'

export default function App() {
  return (
    <div className='container'>
      <Term />
    </div>
  )
}


